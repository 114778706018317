import {
  LoadMoreAction,
  RECEIVED_POSTS_SUCCESSFULLY,
  RECEIVED_POSTS_UNSUCCESSFULLY,
  REQUEST_POSTS,
} from '../loadMore.actions';
import { SECTION_NAMES } from '../../../templates/templates.const';
import { FeedPageMinorSectionWithTitle } from '../template.state.types';

interface FeedPageWithMinorSection {
  [SECTION_NAMES.FEED_SECTION]: FeedPageMinorSectionWithTitle;
}

export const feedPageReducer = <T extends FeedPageWithMinorSection>(state: T = {} as T, action: LoadMoreAction<keyof FeedPageWithMinorSection>) => {
  switch (action.type) {
    case RECEIVED_POSTS_SUCCESSFULLY: {
  const oldArticles = state[action.sectionKey].articles || [];
      return {
        ...state,
        [action.sectionKey]: {
          ...state[action.sectionKey],
          articles: [
            ...oldArticles,
            ...action.articles,
          ],
          showMorePaginationURL: action.next,
        },
      };
    }
    case RECEIVED_POSTS_UNSUCCESSFULLY: {
      return state;
    }

    case REQUEST_POSTS: {
      return state;
    }
    default:
      return state;
  }
};
