import { AppState, FeedPageRomeAppState } from '../../../initialStateRegistration';
import {
    FeedPageRomeTopSection,
    FeedPageRomeMinorSections,
    FeedPageSectionNames,
} from './feedPageRome.initialState';

export const getFeedPage = (state: AppState) => (state as FeedPageRomeAppState).template;

export const getSectionDataByKey = (key: FeedPageSectionNames) => (state: AppState) => getFeedPage(state)[key];

export const getSectionArticlesByKey = (key: FeedPageSectionNames) => (state: AppState) => getFeedPage(state)[key].articles;

export const getSectionTitleByKey = (key: FeedPageRomeTopSection) => (state: AppState) => getFeedPage(state)[key].sectionTitle;

export const getSectionSubtitleByKey = (key: FeedPageRomeTopSection) => (state: AppState) => getFeedPage(state)[key].sectionSubtitle;

export const getShowMorePaginationTextByKey = (key: FeedPageRomeMinorSections) => (state: AppState) => getFeedPage(state)[key].showMorePaginationText;

export const getShowMorePaginationURLByKey = (key: FeedPageRomeMinorSections) => (state: AppState) => getFeedPage(state)[key].showMorePaginationURL;

export const getPagination = (state: AppState) => getFeedPage(state).pagination;

export const getSportradarProps = (state: AppState) => getFeedPage(state).sportradarProps;

export const getMetadataDescription = (state: AppState) => getFeedPage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getFeedPage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getFeedPage(state).metadataImage;

export const getHreflangData = (state: AppState) => getFeedPage(state).hreflangData;

export const getTopic = (state: AppState) => getFeedPage(state).topic;

export const getVertical = (state: AppState) => getFeedPage(state).customVertical;

export const getNetworkLink = (state: AppState) => getFeedPage(state).networkLink;
