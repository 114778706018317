import React from 'react';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import {
  SportradarFactory,
  SPORTRADAR_NAMES,
  MEDIA_BREAKPOINTS,
  baseUnit,
  LARGE_SCREEN_SIZE,
} from 'mm-ui-components';
import { SportradarProps } from '../../store/template/template.state.types';

const createStyle = () => StyleSheet.create({
  headerWidgetContainer: {
    marginBottom: '0 0 20px',
    backgroundColor: 'white',
    boxShadow: '0 4px 4px -2px #ddd',
    minHeight: '125px',
  },
  headerWidgetWrapper: {
    maxWidth: LARGE_SCREEN_SIZE,
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    boxShadow: 'none',
    padding: '20px 0',
    [MEDIA_BREAKPOINTS.medium]: {
      padding: '20px 30px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: '20px',
    },
  },
  headerWidget: {
    boxShadow: 'none',
    margin: '0',
    padding: '0',
  },
  bodyWidgetsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      marginTop: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${0.5 * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `0 ${0.5 * baseUnit}px`,
    },
  },
  hiddenMobileTablet: {
    display: 'block',
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'none',
    },
  },
});

const createSportradarName = (sportradarName: string, league?: string | null) => {
  if (!league) {
    return null;
  }
  const formatLeague = `${league.toUpperCase()}${sportradarName}`;
  // @ts-ignore
  return SPORTRADAR_NAMES[formatLeague];
};

interface FeedPageRomeSRWidgetsProps {
  sportradarProps?: SportradarProps;
  additionalStyle?: StyleDeclarationValue;
}

export const FeedPageRomeSRHeaderWidget = ({
  sportradarProps,
}: FeedPageRomeSRWidgetsProps) => {
  const style = createStyle();
  const { league } = sportradarProps || {};

  return (
    <div className={css(style.headerWidgetContainer)}>
      <SportradarFactory
        sportradarProps={sportradarProps}
        name={createSportradarName('_TEAM_HEADER', league)}
        wrapperStyle={style.headerWidgetWrapper}
        widgetStyle={style.headerWidget}
      />
    </div>
  );
};

export const FeedPageRomeSRBodyWidgets = ({
  sportradarProps,
  additionalStyle,
}: FeedPageRomeSRWidgetsProps) => {
  const style = createStyle();
  const { league } = sportradarProps || {};

  return (
    <div className={css(style.bodyWidgetsWrapper, additionalStyle)}>
      {sportradarProps?.league !== 'ncaaf' ? (
        <SportradarFactory
          name={createSportradarName('_RECENT_GAMES', league)}
          sportradarProps={sportradarProps}
        />
        ) : (
          <SportradarFactory
            name={createSportradarName('_SCHEDULE', league)}
            sportradarProps={sportradarProps}
          />
        )}
      <SportradarFactory
        name={createSportradarName('_STANDINGS_SLIM', league)}
        sportradarProps={sportradarProps}
      />
      <SportradarFactory
        name={createSportradarName('_TEAM_LEADERS', league)}
        sportradarProps={sportradarProps}
        wrapperStyle={style.hiddenMobileTablet}
      />
      <SportradarFactory
        name={createSportradarName('_INJURIES', league)}
        sportradarProps={sportradarProps}
        wrapperStyle={style.hiddenMobileTablet}
      />
      <SportradarFactory
        name={createSportradarName('_RANKS', league)}
        sportradarProps={{
          ...sportradarProps,
          ...sportradarProps?.league === 'mlb' && { title: 'Team Stats Pitching', category: 'pitching' },
        }}
        wrapperStyle={style.hiddenMobileTablet}
      />
      {sportradarProps?.league === 'mlb' && (
        <SportradarFactory
          name={createSportradarName('_RANKS', league)}
          sportradarProps={{
            ...sportradarProps,
            title: 'Team Stats Hitting',
            category: 'hitting',
            widgetId: 'team-stats-hitting',
          }}
          wrapperStyle={style.hiddenMobileTablet}
        />
      )}
    </div>
  );
};
